<template>
  <div class="details-page">
    <div class="header">
      <!-- <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide
          v-for="(item, index) in goodsPicUrls"
          :key="index">
          <div class="pic" :style="{ backgroundImage: `url(${item})` }"></div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper> -->
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide"
            v-for="(item, index) in goodsBannerImage"
            :key="index">
            <div class="pic" :style="{ backgroundImage: `url(${item.accessoryUrl})` }"></div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="detailHeader">
      <div class="detailTitle">{{ goodsDetails.goodsName }}</div>
      <div class="detailContent">
        <p>
          <span class="originPrice">{{ goodsDetails.subTitle }}</span>
          <span>
            剩余
            <span class="goodsStock">{{ goodsDetails.goodsStock }}</span>
            份
          </span>
        </p>
        <p>
          <span>
            <span class="goldPrice">{{ goodsDetails.goldPrice }}</span>
            金豆
          </span>
          <span>限兑换1份</span>
        </p>
      </div>
    </div>
    <div class="detailOther">
      <div class="details-title">商品详情</div>
      <ygdetails v-if="goodsDetails.exchangeType === '1'"/>
      <div class="details-img"
        v-for="(list, idx) in goodsDetailImage" :key="idx">
        <img :src="list.accessoryUrl" alt="" />
      </div>
    </div>
    <div class="details-bottom">
      <div class="btn" @click="visible = true">立即兑换</div>
    </div>
    <y-dialog :visible="visible">
      <div class="head">
        <!-- <img src="~@/assets/images/leaflet/frame.png" alt="" width="100%"> -->
        <h3>温馨提示</h3>
      </div>
      <div class="apply-content">
        <p>是否支付{{ goodsDetails.goldPrice }}金豆兑换</p>
      </div>
      <div class="apply-btn" slot="footer">
        <!-- <el-button type="primary" @click="takenGoldsAction">确认</el-button>
        <el-button type="danger" @click="visible = false">取消</el-button> -->
        <button class="y-button y-button--primary" @click="takenGoldsAction">确认</button>
        <button class="y-button y-button--danger" @click="visible = false">取消</button>
      </div>
    </y-dialog>
    <y-loading v-show="isLoading" />
  </div>
</template>
<script>
import { getAppStatus, callAppHandler } from '@/utils/app';
import { yinggeChannel } from './js/goods';
import YDialog from '@/components/dialog';
import YLoading from '@/components/loading';
import { getQueryGoodsDetail, getExchangeGoods } from '@/api/base';
import goodsList from './js/goodsList.json';
import Ygdetails from './components/ygdetails';
import { isArray } from '@/utils';
import md5 from 'js-md5';

export default {
  name: 'GoodsDetails',
  data() {
    return {
      goodsBannerImage: [],
      goodsDetailImage: [],
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination', // 与slot="pagination"处 class 一致
          clickable: true, // 轮播按钮支持点击
        },
        loop: true,
        autoplay: {
          delay: 2000,
        },
      },
      goodsDetails: {
        goldPrice: 0,
        goodsName: '',
        goodsStock: 0,
        originPrice: 0,
        subTitle: '',
        exchangeType: '',
        yinGeParam: '',
      },
      visible: false,
      isLoading: false,
      cuserId: getAppStatus().cuserId,
      // cuserId: '6d1455cb-cdbb-46c4-911d-8a60a0342fe9',
    };
  },
  components: {
    YLoading,
    YDialog,
    Ygdetails,
  },
  computed: {
    goodsId() {
      const { goodsId } = this.$route.query;
      return goodsId;
    },
    yinggeUrl() {
      return 'https://songzhaopian.com/';
      // return 'https://pre.songzhaopian.com/'; // 印鸽预发
    },
    goodsPicUrls() {
      const { goodsId } = this;
      // console.log(goodsList);
      return goodsList[goodsId].banner;
    },
    goodsDetailList() {
      const { goodsId } = this;
      return goodsList[goodsId].details;
    },
  },
  mounted() {
    document.title = '商品详情';
    this.getDetailsData();
  },
  methods: {
    async getDetailsData() {
      let res;
      const { goodsId, cuserId } = this;
      this.isLoading = true;
      try {
        res = await getQueryGoodsDetail({ goodsId, cuserId });
      } catch (e) {
        console.log(e);
        this.$toast(e?.desc || '网络异常');
      }
      this.isLoading = false;
      if (res?.code === 1) {
        let { bannersList, detailList } = res.results;
        this.goodsBannerImage = isArray(bannersList) ? bannersList : [];
        this.goodsDetailImage = isArray(detailList) ? detailList : [];
        // this.goodsDetailImage.push({
        //   accessoryUrl: 'https://download-cos.yofish.com/baozhen-gongjushiyebu/20211029164306464-detail_bottom.jpg',
        // });
        this.goodsDetails = res.results;

        this.$nextTick(() => {
          const mySwiper = new window.Swiper('.swiper-container', {
            direction: 'horizontal', // 垂直切换选项
            loop: true, // 循环模式选项
            // 如果需要分页器
            pagination: {
              el: '.swiper-pagination',
              clickable: true, // 轮播按钮支持点击
            },
            autoplay: {
              delay: 2000,
            },
          });
        });
      }
    },
    async takenGoldsAction() {
      let res;
      const { goodsId, cuserId, yinggeUrl } = this;
      const { origin } = window.location;
      try {
        res = await getExchangeGoods({
          goodsId,
          cuserId,
        });
      } catch (e) {
        console.log(e);
        this.visible = false;
        // let gerId = '';
        // let wid = '';
        // let url = origin + `/gold/order?gerId=${gerId}&goodsId=${goodsId}&wid=${wid}`;
        // url = encodeURIComponent(url);
        // window.open(yinggeUrl + 'reseller-youyujizhang/?outProductId='
        // + goodsId + '&outUserId=' + cuserId + '&callback=' + url, '_self');
        return this.$toast({ content: e.desc, duration: 2000 });
      }
      if (res?.code === 1) {
        this.visible = false;
        const { record } = res.results;
        const { gerId, winnerAddress } = record;
        const { wid } = winnerAddress;
        const { goodsDetails } = this;
        sessionStorage.setItem('wid', wid);
        sessionStorage.setItem('gerId', gerId);
        let url = origin + `/gold/order?gerId=${gerId}&goodsId=${goodsId}&wid=${wid}`;
        url = encodeURIComponent(url);
        if (goodsDetails.exchangeType === '1') { // 不包邮
          let channel = goodsDetails.yinGeParam;
          let openid = md5(this.cuserId);
          // let tpFlag = md5(JSON.stringify({ yyjz: openid }));
          let tpFlag = md5(`yyjz:${openid}`);
          let gid = openid + tpFlag;
          window.open(`${yinggeUrl}?gid=${gid}&tp_flag=yyjz&channel=${channel}&title=%E5%8D%B0%E9%B8%BD`, '_self');
        } else {
          window.open(yinggeUrl + 'reseller-youyujizhang/?outProductId='
        + goodsId + '&outUserId=' + cuserId + '&callback=' + url, '_self');
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.details-page {
  width: 100%;
  background: #ffffff;
  padding-bottom: 150px;
  font-family: Avenir,Helvetica,Arial,sans-serif;
  .pic {
    width: 100%;
    height: 10rem;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat
  }
  .head h3 {
    font-size: 35px;
    text-align: center;
    padding-top: 40px;
  }
  .apply-content {
    font-size: 30px;
    text-align: center;
    margin-top: 20px;
  }
  .apply-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 80px;
    .y-button {
      flex: 0 0 auto;
      width: 200px;
      height: 70px;
      font-size: 28px;
      border-radius: 40px;
    }
  }
}
.detailHeader {
  background: #ffffff;
  padding: 0.32rem 0.293333rem;
  box-sizing: border-box;
  border-bottom: 1px solid #dddddd;
  box-sizing: border-box;
}
.detailTitle {
  font-size: 0.4rem;
  font-weight: 500;
  color: #333333;
  margin-bottom: 0.346666rem;
}
.detailContent {
  width: 100%;
}
.detailContent > p {
  font-size: 0;
  margin-bottom: 0.106666rem;
}
.detailContent > p:last-child {
  margin-bottom: 0;
}
.detailContent > p > span {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
  font-size: 0.346666rem;
  color: #999999;
}
.detailContent > p > span:nth-of-type(2) {
  text-align: right;
}
.detailContent > p > span > span {
  font-size: 0.426666rem;
  color: #ff3636;
  font-weight: 500;
}
.detailOther {
  .details-title {
    text-align: center;
    padding: 15px 0;
    font-size: 32px;
    display: flex;
    align-items: center;
    font-weight: 800;
    justify-content: center;
    &::before, &::after {
      content: '';
      width: 80px;
      height: 2px;
      background-color: rgba(#999999, .3);
      display: inline-block;
    }
    &::before {
      margin-right: 20px;
    }
    &::after {
      margin-left: 20px;
    }
  }
}
.details-bottom {
  width: 100%;
  height: 1.86667rem;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #fff;
   -webkit-box-shadow: 0 -.10667rem .10667rem 0 rgba(0,0,0,.04);
  box-shadow: 0 -.10667rem .10667rem 0 rgba(0,0,0,.04);
  display: flex;
  align-items: center;
  justify-content: center;
  .btn {
    // margin: .13333rem .37333rem;
    width: 90%;
    height: 1.17333rem;
    background: linear-gradient(95deg,#ff4a4a,#ff3636);
    border-radius: .58667rem;
    font-size: .45333rem;
    font-weight: 500;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.details-img {
  width: 100%;
  img {
    width: 100%;
  }
}
</style>
<style>
  .swiper-slide{
    transform: translate3d(0, 0, 0);
  }
</style>
