<template>
  <div class="yg-details">
    <p>订单查询及客服咨询：</p>
    <p><a :href="`https://songzhaopian.com/order_list?gid=${gid}&tp_flag=yyjz`">点我即可进入印鸽商城查询订单</a>
    进入后在底部的“订单”板块即可查询或直接联系在线客服 (8:00-24:00），也可拨打印鸽客服热线：4000399608 (9:00-21:00）咨询。</p>
    <br/>
    <p>使用规则：</p>
    <p>1、用户兑换后即可进入印鸽商城领取该商品抵扣券，该商品将自动在支付时抵扣，用户可自行选择加钱定制;</p>
    <p>2、权益兑换后有效期为15天 （含兑换当天），在有效期内未使用，将自动作废。优惠券领取后不可取消不可退换，已消耗的金豆不退还；</p>
    <p>3、券后商品0元，邮费需要自理：甘肃省、青海省、海南省内蒙古自治区、宁夏回族自治区10元，新疆、西藏12元，其余地方8元。在订单结算页面可看到具体邮费价格（港澳台不发货）；</p>
    <p>4、除特殊注明外，本优惠不能与其他优惠同时享受；代金券仅限领券的用户账号使用，无法转赠他人；</p>
    <p>5、定制商品无质量问题不退不换。若因质量问题产生的交易退款，仅退回用户实际支付的金额，优惠资格不再补偿；</p>
    <p>6、权益由杭州印鸽科技有限公司提供，如有疑问在印鸽商城首页联系客服 (8:00-24:00） 或者拨打客服热线：4000399608 (9:00-21:00)</p>
    <br/>
    <p>温馨提示: </p>
    <p>1、请勿上传涉政、涉黄、暴力、品牌logo等相关的图进行定制哦，印鸽一经发现会做订单拦截和退款处理，退款后优惠券在有效期内仍可继续使用。
      若因上传的图片问题导致的退款，金豆不予退还。谢谢配合！</p>
    <p>2、金豆一经兑换，不予退回（兑换的免单券/现金券无法使用情况除外，可联系有鱼客服处理）。印鸽商城其他定制品或者免单券商品的升级品可能因成本或其他因素会产生调价情况，
      属正常现象，但不影响免单券商品的免单券使用，不能作为金豆退还的原因，谢谢配合。</p>
    <br/>
    <p>兑换流程：</p>
    <p>1、使用金豆兑换优惠券—兑换后点击去使用一跳转商品界面-领取商品一订单结算页自动选择优惠券抵扣该商品金额一唤起支付（邮费)一完成商品购买；</p>
    <p>2、若兑换后不小心退出页面，可前往“兑换记录一查看订单”继续下单或查看订单。</p>
  </div>
</template>
<script>
import md5 from 'js-md5';
import { getAppStatus } from '@/utils/app';

export default {
  name: 'Ygdetails',
  computed: {
    cuserId() {
      return getAppStatus().cuserId;
    },
    gid() {
      let gid = '';
      if (this.cuserId) {
        let openid = md5(this.cuserId);
        let tpFlag = md5(`yyjz:${openid}`);
        gid = openid + tpFlag;
      }
      return gid;
    },
  },
};
</script>
<style lang="scss" scoped>
.yg-details {
  padding: 0.12rem 0.293333rem;
  font-size: 0.346666rem;
  color: #666;
  a {
    text-decoration: none
  }
}
</style>
